import React, { useState, useEffect, useRef } from 'react';

// time reciprocal
function paddedFormat(num) {
    return num < 10 ? '0' + num : num;
}

function Content() {

    const [counter, setCount] = useState(0); // 狀態管理計時器數值
    const intervalRef = useRef(null); // 用來保存計時器的 ID

    // 等待 DOM 渲染完成後操作
    useEffect(() => {

        // set timer
        const now = Date.now();
        const targetDate = new Date('05/17/2025');
        var allTimeSec = Math.trunc((targetDate - now) / 1000) + 43200; // 43200=12pm
        intervalRef.current = setInterval(() => {
            --allTimeSec;
            var time_day = parseInt(allTimeSec / 60 / 60 / 24);
            var time_hour = parseInt(allTimeSec / 60 / 60 % 24);
            var time_minutes = parseInt(allTimeSec / 60 % 60);
            var time_seconds = allTimeSec % 60;
            setCount(() => time_day +
                `日 ${paddedFormat(time_hour)}時 ${paddedFormat(time_minutes)}分 ${paddedFormat(time_seconds)}秒`); // 更新計數器
        }, 1000);

        // 組件卸載時執行
        return () => {
            clearInterval(intervalRef.current);
        }

    }, []); // 空依賴陣列確保只在組件第一次渲染時執行

    // Photo & Video
    const showgallery = (event, type) => {
        // Prevent default anchor click behavior
        event.preventDefault();
        if (type === 'photo') {
            console.log('clicked photo');
        } else if (type === 'video') {
            console.log('clicked video');
        }
    }

    // submit to insert & query DB data
    const [cusName, setName] = useState('');
    const [peopleCnt, setPeopleOption] = useState('1');
    const [vegCnt, setVegOption] = useState('0');
    const [email, setEmail] = useState('');
    const [aMessage, setAMessage] = useState('');
    const [response, setResponse] = useState('');
    const [dataList] = useState([]);
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (cusName.trim() === '') {
            alert("請填寫您的大名～");
            return;
        }

        if (vegCnt > peopleCnt) {
            alert("素食人數 超過 參加人數，請確認一下唷～");
            return;
        }

        try {
            // const res = await fetch('http://localhost:8081/submit', {   // local test
            const res = await fetch('/api/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cusName, peopleCnt, vegCnt, email, aMessage }),
            });

            if (res.ok) {
                const data = await res.text();
                console.log("data = " + data);
                setResponse(data);
                console.log("送出完成");
            } else {
                setResponse('Failed to submit data.');
                alert("送出失敗，請再試一次");
            }
        } catch (error) {
            setResponse('Error: ' + error.message);
            alert("送出失敗，請再試一次");
        }
    };

    return (
        <main>
            <div className="container p-4">
                <img src={require('./../assets/images/frontCover.jpg')} className="img-fluid" alt="frontCover" />
            </div>
            <div className="container p-1 my-1">
                <div className="row">
                    <div className="col textVertical" />
                    <div className="col textVertical">
                        <h3>
                            <u>與我們一同慶祝</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h3>
                            <u>邀請大家五月十七號</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h3>
                            <u>我們結婚了！</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h1>朱瑞三 × 黃怡樺</h1>
                    </div>
                    <div className="col textVertical" />
                </div>
            </div>
            <div className="container imgPadding">
                <img src={require('./../assets/images/A1.jpg')} className="img-fluid" alt="imgA1" />
            </div>
            <div className="container imgLeft">
                <img src={require('./../assets/images/A2.jpg')} className="img-fluid" alt="imgA2" />
            </div>
            <div className="container p-1 my-1">
                <div className="row">
                    <div className="col textVertical" />
                    <div className="col textVertical">
                        <h3>朱瑞三</h3>
                        <br />
                        <h3>　 ×</h3>
                        <br />
                        <h3>　黃怡樺</h3>
                    </div>
                    <div className="col textVertical" />
                </div>
            </div>
            <div className="container imgRight">
                <img src={require('./../assets/images/A3.jpg')} className="img-fluid" alt="imgA3" />
            </div>
            <br />
            <br />
            <br />
            <div className="container imgHorizontalLeft">
                <img src={require('./../assets/images/B1.jpg')} className="img-fluid" alt="imgB1" />
            </div>
            <div className="container imgHorizontalRight">
                <img src={require('./../assets/images/B2.jpg')} className="img-fluid" alt="imgB2" />
            </div>
            <div className="container imgHorizontalLeft">
                <img src={require('./../assets/images/B3.jpg')} className="img-fluid" alt="imgB3" />
            </div>
            <br />
            <br />
            <br />
            <div className="container p-1 my-1">
                <div className="row">
                    <div className="col textVertical" />
                    <div className="col textVertical">
                        <h3>
                            <u>與我們一同慶祝！</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h3>
                            <u>邀請您們前往新悅（嘉義最好吃的餐廳）</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h3>
                            <u>二◯二五年五月十七日（六）</u>
                        </h3>
                    </div>
                    <div className="col textVertical">
                        <h1>一起慶祝吧</h1>
                    </div>
                    <div className="col textVertical" />
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="container p-1 my-1">
                <div className="textPadding">
                    <h1>
                        <b>新悅（嘉義最好吃的餐廳）</b>
                    </h1>
                    <h3>
                        <u>嘉義市東區保順路69號</u>
                    </h3>
                </div>
                <br />
                <div className="textPadding">
                    <h3>
                        <b>開車前來的朋友</b>
                    </h3>
                    <h3>用Google直接導航開過來就好</h3>
                </div>
                <br />
                <div className="textPadding">
                    <h3>
                        <b>搭乘捷運的朋友</b>
                    </h3>
                    <h3>啊！嘉義沒有捷運</h3>
                </div>
                <br />
                <div className="textPadding">
                    <h3>
                        <b>搭乘公車的朋友</b>
                    </h3>
                    <h3>自己Google一下我也不知道</h3>
                </div>
            </div>
            <br />
            <div className="container p-1 my-5">
                <div className="row">
                    <div
                        className="col-2 textVertical"
                        style={{ paddingLeft: 40, paddingRight: 80 }}
                    >
                        <h3>
                            <b>當日流程</b>
                        </h3>
                    </div>
                    <div className="col-3">
                        <div className="row textCircle border border-2 align-items-center">
                            <h3>
                                11:00
                                <br />
                                AM
                            </h3>
                        </div>
                        <div className="row textCircle border border-2 align-items-center">
                            <h3>
                                11:30
                                <br />
                                AM
                            </h3>
                        </div>
                        <div className="row textCircle border border-2 align-items-center">
                            <h3>
                                12:00
                                <br />
                                AM
                            </h3>
                        </div>
                        <div className="row textCircle border border-2 align-items-center">
                            <h3>
                                01:00
                                <br />
                                PM
                            </h3>
                        </div>
                        <div className="row textCircle border border-2 align-items-center">
                            <h3>
                                02:00
                                <br />
                                PM
                            </h3>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row textFlow align-items-center">
                            <h3>大家好</h3>
                        </div>
                        <div className="row textFlow align-items-center">
                            <h3>證婚</h3>
                        </div>
                        <div className="row textFlow align-items-center">
                            <h3>吃飯飯</h3>
                        </div>
                        <div className="row textFlow align-items-center">
                            <h3>放鬆一下</h3>
                        </div>
                        <div className="row textFlow align-items-center">
                            <h3>
                                快樂的時光
                                <br />
                                總是特別快
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dressCode">
                <div className="dressCodeTitle">
                    <h2>Dress Code</h2>
                </div>
                <div className="colorBox">
                    <div className="color" id="color1" />
                    <div className="color" id="color2" />
                    <div className="color" id="color3">
                        <p>Purple</p>
                    </div>
                </div>
                <p>誠邀著上述色系服裝出席</p>
            </div>
            <div className="photo-video">
                <div className="photo-area">
                    <h2>婚紗</h2>
                    <div
                        id="carouselExampleInterval"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval={2000}>
                                <img src={require('./../assets/images/A1.jpg')} className="d-block w-100" alt="imgA1" />
                            </div>
                            <div className="carousel-item" data-bs-interval={2000}>
                                <img src={require('./../assets/images/A1.jpg')} className="d-block w-100" alt="imgA2" />
                            </div>
                            <div className="carousel-item" data-bs-interval={2000}>
                                <img src={require('./../assets/images/A1.jpg')} className="d-block w-100" alt="imgA3" />
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleInterval"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleInterval"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className="button-area">
                        <a href="#" onClick={(event) => showgallery(event, 'photo')}>
                            MORE
                            <br />
                            PHOTO
                        </a>
                    </div>
                </div>
                <div className="video-area">
                    <h2>花絮</h2>
                    <div className="button-area">
                        <a href="#" onClick={(event) => showgallery(event, 'video')}>
                            PLAY
                            <br />
                            VIDEO
                        </a>
                    </div>
                    <div>
                        <img src={require('./../assets/images/A1.jpg')} className="d-block w-100" alt="imgA1" />
                    </div>
                </div>
            </div>
            <div className="container p-5 my-5">
                <h1 className="text-center textReciprocal">
                    <b>婚禮倒數</b>
                </h1>
                <h1 className="text-center textReciprocal">
                    {counter}
                </h1>
                <h3 className="text-center textReciprocal">
                    <u>期待與你們相見！</u>
                </h3>
            </div>

            <h1 className="text-center textReciprocal">
                <b>出席表單</b>
            </h1>
            <form onSubmit={handleSubmit}>
                <h2 className="text-center textReciprocal">
                    <b>姓名</b>
                </h2>
                <input
                    id="cusName"
                    value={cusName}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control form-control-lg mb-3"
                    type="text"
                    maxLength={20}
                    aria-label=".form-control-lg"
                />
                <h2 className="text-center textReciprocal">
                    <b>參加人數（含自己）</b>
                </h2>
                <select
                    id="peopleCnt"
                    value={peopleCnt}
                    onChange={(e) => setPeopleOption(e.target.value)}
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg">
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                </select>
                <h2 className="text-center textReciprocal">
                    <b>素食人數(含自己)</b>
                </h2>
                <select
                    id="vegCnt"
                    value={vegCnt}
                    onChange={(e) => setVegOption(e.target.value)}
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg">
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                </select>
                <h2 className="text-center textReciprocal">
                    <b>電子喜帖寄送Email (選填)</b>
                </h2>
                <input
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    maxLength={100}
                    placeholder="email@address.com"
                    autoComplete="on"
                />
                <h2 className="text-center textReciprocal">
                    <b>Leave a message (選填)</b>
                </h2>
                <textarea
                    id="aMessage"
                    value={aMessage}
                    onChange={(e) => setAMessage(e.target.value)}
                    className="form-control"
                    maxLength={1000}
                    rows={3}
                />
                <button type="submit">Submittt</button>
            </form>
            <pre>{response}</pre>
            <ul>
                {dataList.map((item, index) => (
                    <li key={index}>{item.text}</li>
                ))}
            </ul>

            <button type="button" className="btn btn-secondary" id="topBtn">
                <i className="bi bi-arrow-up-short" />
            </button>
            <div className="dressCode">
                <h1>Thank you for watching</h1>
                <h1>Side Project from CHU JUI-SAN</h1>
            </div>
        </main>
    );
}

export default Content;
