// src/App.js
import React from 'react';
import './assets/css/bootstrap.min.css'; // 引入 Bootstrap CSS
import './assets/css/styles.css'; // 我的 CSS 
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import GoTop from './components/GoTop';
//import $ from 'jquery';
// require ('./assets/js/scripts.js');

function App() {
  //window.$ = $;
  //window.jQuery = $;

  return (
    <div className="container">
      <Header />
      <Content />
      <Footer />
      <GoTop />
    </div>
  );
}

export default App;
