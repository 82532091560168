import React from 'react';

function Footer() {
    return (
        <footer className="bg-dark text-white text-center py-3">
            <p>© 2024 My Website</p>
        </footer>
    );
}

export default Footer;
