import React, { useState, useEffect } from 'react';

function GoTop() {

    const [showButton, setShowGoTop] = useState(false);

    // 等待 DOM 渲染完成後操作
    useEffect(() => {

        // 當頁面滾動時，檢查滾動位置
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowGoTop(true);
            } else {
                setShowGoTop(false);
            }
        };

        // goTop Listener
        window.addEventListener('scroll', handleScroll);

        // 組件卸載時執行
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []); // 空依賴陣列確保只在組件第一次渲染時執行

    // 回到頂部功能
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // 平滑滾動效果
        });
    };

    return (
        <div>
            {showButton && (
                <button className="btn"
                    style={{
                        position: 'fixed', fontSize: '1rem',
                        bottom: '30px', right: '20px',
                        border: '3px dashed rgba(255,150,200,1)',
                        borderRadius: '50%'
                    }}
                    onClick={scrollToTop}>
                    <i className="bi bi-caret-up-fill" style={{color: 'rgba(255,150,200,1)'}}></i>
                </button>
            )}
        </div>
    );

}

export default GoTop;
